import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { getMedicalHistories, getMedicalHistoriesReportRegistration } from '../../core/_requests'
import { MedicalHistories } from '../../core/_models'
import { ID } from '../../../../../../../_metronic/helpers'

interface Props {
  show: boolean
  onHide: () => void
  children_id: ID
}

const ExportMedicalHistoriesModal: React.FC<Props> = ({ show, onHide, children_id }) => {
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])
  const [selectedMedicine, setSelectedMedicine] = useState('')
  const [loading, setLoading] = useState(false)
  const [medicines, setMedicines] = useState<MedicalHistories[]>([])
  const [availableMonths, setAvailableMonths] = useState<string[]>([])
  const [medicinesLoading, setMedicinesLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setSelectedMonths([])
      setSelectedMedicine('')

      setMedicinesLoading(true)
      getMedicalHistories(children_id)
        .then((res) => {
          const data: MedicalHistories[] = (res.data) ? res.data : [];
          var uniques: MedicalHistories[] = [];
          data.forEach((m) => {
              if (uniques.filter(f => f.medication_name === m.medication_name).length === 0) {
                  uniques.push(m);
              }
          });
          setMedicines(uniques);

          const now = new Date()
          const uniquesMonths: string[] = []
          
          for (let i = 0; i < 24; i++) {
            const d = new Date(now.getFullYear(), now.getMonth() - i, 1)
            const year = d.getFullYear()
            const month = String(d.getMonth() + 1).padStart(2, '0') // mês começa do zero
            uniquesMonths.push(`${year}-${month}`)
          }
          
          setAvailableMonths(uniquesMonths)
        })
        .catch((err) => {
          console.error('Erro ao carregar medicamentos:', err)
          setMedicines([])
        })
        .finally(() => {
          setMedicinesLoading(false)
        })
    }
  }, [show, children_id])

  const handleGenerate = async () => {
    if (!selectedMedicine || selectedMonths.length === 0) return

    setLoading(true)
    try {
      const report = await getMedicalHistoriesReportRegistration(
        children_id,
        parseInt(selectedMedicine),
        selectedMonths // ← Passando array de meses
      )

      const blob = new Blob([report], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `historico-${children_id}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      console.log('✅ PDF baixado com sucesso.')
    } catch (error) {
      console.error('❌ Erro ao gerar PDF:', error)
    } finally {
      setLoading(false)
      onHide()
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Histórico de uso em PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formMedicine'>
            <Form.Label>Medicamento</Form.Label>
            <Form.Control
              as='select'
              value={selectedMedicine}
              onChange={(e) => setSelectedMedicine(e.target.value)}
              disabled={medicinesLoading}
            >
              <option value=''>Selecione...</option>
              {medicines.map((med, i) => (
                <option key={i} value={med.medication_id}>
                  {med.medication_name}
                </option>
              ))}
            </Form.Control>
            {medicinesLoading && <Form.Text>Carregando medicamentos...</Form.Text>}
          </Form.Group>

          <Form.Group controlId='formMonths' className='mt-3'>
            <Form.Label>Meses</Form.Label>
            <Form.Control
  as='select'
  multiple
  value={selectedMonths}
  onChange={(e) => {
    const target = e.target as unknown as HTMLSelectElement
const selected = Array.from(target.selectedOptions, option => option.value)
    setSelectedMonths(selected)
  }}
>
  {availableMonths.map((month, i) => (
    <option key={i} value={month}>
      {month}
    </option>
  ))}
</Form.Control>
            <Form.Text className='text-muted'>
              Segure Ctrl (Windows) ou Command (Mac) para selecionar vários.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Cancelar
        </Button>
        <Button
          variant='primary'
          onClick={handleGenerate}
          disabled={loading || !selectedMedicine || selectedMonths.length === 0}
        >
          {loading ? (
            <>
              <Spinner size='sm' animation='border' className='me-2' />
              Gerando...
            </>
          ) : (
            'Gerar PDF'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ExportMedicalHistoriesModal
