import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Guardians,
  GuardiansQueryResponse,
  MedicalHistoriesQueryResponse,
  MedicalHistoriesRegistrationQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getFilter = (query: any): Promise<GuardiansQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/childrens/filter`, {params: query})
    .then((d: AxiosResponse<GuardiansQueryResponse>) => {
      return d.data;
    })
}

const getMedicalHistories = (query: any): Promise<MedicalHistoriesQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/childrens/medical-history?children_id=${query}`, {params: query})
    .then((d: AxiosResponse<MedicalHistoriesQueryResponse>) => {
      return d.data;
    })
}

const getMedicalHistoriesRegistration = (query: any): Promise<MedicalHistoriesRegistrationQueryResponse> => {
  return axios
    .get(`${API_URL}/admin/childrens/medical-history-registration?children_id=${query}`, {params: query})
    .then((d: AxiosResponse<MedicalHistoriesRegistrationQueryResponse>) => {
      return d.data;
    })
}

const getMedicalHistoriesReportRegistration = (children_id: ID, medication_id: number, month_year: string[]): Promise<any> => {
  return axios
    .get(`${API_URL}/admin/childrens/medical-history-report?children_id=${children_id}&medication_id=${medication_id}&month_year=${month_year}`, {params: {}, responseType: 'arraybuffer'})
    .then((d: AxiosResponse<MedicalHistoriesRegistrationQueryResponse>) => {
      return d.data;
    })
}

const update = (meetings: Guardians): Promise<Guardians | undefined> => {
  return axios
    .post(`${API_URL}/admin/guardians/update/${meetings.id}`, meetings)
    .then((response: AxiosResponse<Response<Guardians>>) => response.data)
    .then((response: Response<Guardians>) => response.data)
}

export {
  update,
  getFilter,
  getMedicalHistories,
  getMedicalHistoriesRegistration,
  getMedicalHistoriesReportRegistration
}
