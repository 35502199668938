/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import exportFromJSON from 'export-from-json' 
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import { MedicalHistories, MedicalHistoriesRegistration } from '../../core/_models'
import {getMedicalHistories, getMedicalHistoriesRegistration} from '../../core/_requests'
import ExportMedicalHistoriesModal from '../../components/modal/ExportMedicalHistoriesModal'

type Props = {
  id: ID
}

const ActionsCell: FC<Props> = ({id}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const exportAllMedicalHistoriesChildrensXLS = async () => {
    const res = await getMedicalHistories(id);
    const data: MedicalHistories[] = (res.data) ? res.data : []

    const fileName = 'getinOxy-xls-childrens-medical-histories';
      const exportType = 'xls';
      exportFromJSON({ data, fileName, exportType });
    
  }
  
  const exportAllMedicalHistoriesRegistrationChildrensXLS = async () => {
    const res = await getMedicalHistoriesRegistration(id);
    const data: MedicalHistoriesRegistration[] = (res.data) ? res.data : []
    const fileName = 'getinOxy-xls-childrens-medical-histories-registration';
    const exportType = 'xls';
    exportFromJSON({ data, fileName, exportType });
  }


  const exportMedicalHistoriesRegistrationChildrensPDF = async () => {
    setShowModal(true);
  }

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      
      <ExportMedicalHistoriesModal show={showModal} onHide={() => setShowModal(false)} children_id={id} />
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='activate_row'
            onClick={() => exportAllMedicalHistoriesChildrensXLS()}
          >
            Exportar Histórico Médico
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='activate_row'
            onClick={exportAllMedicalHistoriesRegistrationChildrensXLS}
          >
            Exportar Histórico de Uso
          </a>
        </div>
        
        {/* end::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='activate_row'
            onClick={() => exportMedicalHistoriesRegistrationChildrensPDF()}
          >
            Exportar Histórico de Uso em PDF
          </a>
        </div>
      </div>
    </>
  )
}

export {ActionsCell}
