import React, { useMemo, useState, useEffect } from 'react';
import { useTable, ColumnInstance, Row } from 'react-table';
import { CustomHeaderColumn } from './columns/CustomHeaderColumn';
import { CustomRow } from './columns/CustomRow';
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider';
import { categoriesColumns } from './columns/_columns';
import { Guardians } from '../core/_models';
import { ListLoading } from '../components/loading/ListLoading';
import { ListPagination } from '../components/pagination/ListPagination';
import { KTCardBody } from '../../../../../../_metronic/helpers';
import { deleteVideo } from '../core/_requests';

type PropTypes = {
  hiddenColumns?: string[];
};

const Table = ({ hiddenColumns = [] }: PropTypes) => {
  const initialData = useQueryResponseData();
  const isLoading = useQueryResponseLoading();

  // Inicializa o estado como um array vazio
  const [categories, setCategories] = useState<Guardians[]>([]);

  // Atualiza o estado quando os dados estiverem disponíveis
  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setCategories(initialData);
    }
  }, [initialData]);

  // Função para remover um item pelo ID
  const handleDelete = (id: number) => {
    if (window.confirm('Tem certeza que deseja excluir este vídeo?')) {
      deleteVideo(id)
      .then(() => setCategories(
        (prevCategories) => prevCategories.filter((category) => category.id !== id)));
    }
  };

  // Memoriza os dados e as colunas para evitar re-renderizações desnecessárias
  const data = useMemo(() => categories, [categories]);

  const columns = useMemo(
    () =>
      categoriesColumns.map((column) => {
        if (column.id === 'actions') {
          return {
            ...column,
            Cell: ({ row }: { row: Row<Guardians> }) => (
              <div>
                <button
                  onClick={() => handleDelete(row.original.id!)}
                  className="btn btn-danger btn-sm"
                >
                  Excluir
                </button>
              </div>
            ),
          };
        }
        return column;
      }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
              >
                {headerGroup.headers
                  .filter((column: ColumnInstance<Guardians>) => !hiddenColumns.includes(column.id))
                  .map((column: ColumnInstance<Guardians>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Guardians>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    hiddenColumns={hiddenColumns}
                  />
                );
              })
            ) : (
              !isLoading && (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Nenhum registro correspondente encontrado
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <ListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  );
};

export { Table };
